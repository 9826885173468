var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('v-dialog',{attrs:{"max-width":"1044px"},on:{"click:outside":function($event){return _vm.close()}},model:{value:(_vm.isOpen),callback:function ($$v) {_vm.isOpen=$$v},expression:"isOpen"}},[_c('v-card',{staticClass:"pa-6 ma-0"},[_c('v-container',{staticClass:"pa-0"},[_c('v-row',{staticClass:"my-0",attrs:{"align-content":"center"}},[_c('v-col',{staticClass:"pa-0",attrs:{"align-self":"center","cols":"9"}},[_c('v-card-title',{staticClass:"pt-0"},[_vm._v(" "+_vm._s(_vm.$t('sets.newSet').toUpperCase())+" ")])],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('v-divider')],1),_c('v-responsive')],1),_c('div',{staticClass:"my-4",style:(_vm.configuredCouriers.length
            ? {
                display: 'grid',
                'grid-template-columns': 'repeat(4, minmax(23%, 1fr))',
                'grid-template-rows': 'repeat(3, 1fr)',
                gap: '1rem',
              }
            : {
                display: 'grid',
                'grid-template-columns': 'repeat(4, minmax(23%, 1fr))',
                gap: '2rem',
              })},[_c('button',{directives:[{name:"ripple",rawName:"v-ripple"}],staticClass:"courier-logo",staticStyle:{"height":"128px"},attrs:{"type":"button"},on:{"click":function($event){return _vm.selectSpeditor({ code: 'CustomShipment' })}}},[_c('v-icon',{attrs:{"size":"34","color":"primary lighten-1"}},[_vm._v(" mdi-package-variant-closed ")]),_vm._v(" "+_vm._s(_vm.$t('courierSet.differentShippingMethod'))+" ")],1),_vm._l((_vm.configuredCouriers),function(speditor){return _c('button',{directives:[{name:"ripple",rawName:"v-ripple"}],key:_vm.$t(speditor.translationCode),staticClass:"courier-logo",attrs:{"type":"button"},on:{"click":function($event){return _vm.selectSpeditor(speditor)}}},[_c('v-img',{staticClass:"center",attrs:{"src":_vm.logo(speditor.code),"contain":"","max-height":"100%","max-width":"100%"}})],1)})],2),(!!_vm.unconfiguredCouriers.length)?_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"12"}},[_c('span',{staticClass:"d-block"},[_vm._v(_vm._s(_vm.$t('sets.unconfCouriers')))])]),_vm._l((_vm.unconfiguredCouriers),function(speditor){return _c('v-col',{key:_vm.$t(speditor.translationCode),staticClass:"py-0",attrs:{"cols":"3"}},[_c('div',{staticClass:"courier-logo",attrs:{"tabindex":"0"},on:{"click":function($event){return _vm.selectSpeditor(speditor)}}},[_c('v-img',{attrs:{"src":_vm.logo(speditor.code),"contain":"","height":"128","width":"241px"}})],1)])}),_c('v-responsive')],2):_vm._e()],1)],1)],1)
}
var staticRenderFns = []

export { render, staticRenderFns }