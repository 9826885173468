<template>
  <v-dialog
    v-model="isOpen"
    max-width="1044px"
    @click:outside="close()"
  >
    <v-card class="pa-6 ma-0">
      <v-container class="pa-0">
        <v-row
          align-content="center"
          class="my-0"
        >
          <v-col
            align-self="center"
            class="pa-0"
            cols="9"
          >
            <v-card-title class="pt-0">
              {{ $t('sets.newSet').toUpperCase() }}
            </v-card-title>
          </v-col>
          <v-col
            cols="12"
            class="py-0"
          >
            <v-divider />
          </v-col>
          <v-responsive />
        </v-row>

        <div
          class="my-4"
          :style="
            configuredCouriers.length
              ? {
                  display: 'grid',
                  'grid-template-columns': 'repeat(4, minmax(23%, 1fr))',
                  'grid-template-rows': 'repeat(3, 1fr)',
                  gap: '1rem',
                }
              : {
                  display: 'grid',
                  'grid-template-columns': 'repeat(4, minmax(23%, 1fr))',
                  gap: '2rem',
                }
          "
        >
          <button
            v-ripple
            type="button"
            class="courier-logo"
            style="height: 128px"
            @click="selectSpeditor({ code: 'CustomShipment' })"
          >
            <v-icon
              size="34"
              color="primary lighten-1"
            >
              mdi-package-variant-closed
            </v-icon>
            {{ $t('courierSet.differentShippingMethod') }}
          </button>
          <button
            v-for="speditor in configuredCouriers"
            :key="$t(speditor.translationCode)"
            v-ripple
            type="button"
            class="courier-logo"
            @click="selectSpeditor(speditor)"
          >
            <v-img
              :src="logo(speditor.code)"
              contain
              max-height="100%"
              max-width="100%"
              class="center"
            />
          </button>
        </div>

        <v-row v-if="!!unconfiguredCouriers.length">
          <v-col
            cols="12"
            class="py-0"
          >
            <span class="d-block">{{ $t('sets.unconfCouriers') }}</span>
          </v-col>
          <v-col
            v-for="speditor in unconfiguredCouriers"
            :key="$t(speditor.translationCode)"
            cols="3"
            class="py-0"
          >
            <div
              class="courier-logo"
              tabindex="0"
              @click="selectSpeditor(speditor)"
            >
              <v-img
                :src="logo(speditor.code)"
                contain
                height="128"
                width="241px"
              />
            </div>
          </v-col>
          <v-responsive />
        </v-row>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script lang="ts">
import { speditors } from '@/constants/Speditors';
import Vue from 'vue';

export default Vue.extend({
  props: {
    isOpen: Boolean,
    couriers: { type: Array, default: () => [] },
  },

  data: () => ({
    speditors,
    unconfiguredCouriers: [],
    configuredCouriers: [],
  }),

  watch: {
    couriers(newValue) {
      this.configuredCouriers = this.speditors.filter((sped) =>
        newValue.find((c) => c.toLowerCase() === sped.code.toLowerCase()),
      );
      this.unconfiguredCouriers = this.speditors.filter(
        (sped) => !this.configuredCouriers.includes(sped),
      );
    },
  },

  methods: {
    close() {
      this.$emit('@close');
    },

    logo(speditor) {
      const spedLogo = this.speditors.find(
        (x) => x.code.toLowerCase() === speditor.toLowerCase(),
      ).logo;
      const images = require.context('@/assets/images/logo/speditors/', false, /\.png$/);
      return images(`./${spedLogo}`);
    },

    selectSpeditor(speditor) {
      this.$router.push({
        name: `user-configuration-set-${speditor.code.toLowerCase()}-new`,
        params: {
          speditor: speditor.code,
        },
      });
    },
  },
});
</script>

<style scoped lang="scss">
@import '@/assets/styles/utilities/variables';

.courier-logo {
  border: 2px solid rgba(grey, 0.2);
  border-radius: 0.375rem;

  &:hover,
  &:focus,
  &:focus-visible {
    border: 2px solid $primary-lighten1;
  }
}
</style>
